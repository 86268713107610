import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { FolderModel } from '../../models/folder.model';
import { ReceiptModel } from '../../models/receipt.model';

@Component({
  selector: 'app-receipt-detail',
  templateUrl: './receipt-detail.page.html',
  styleUrls: ['./receipt-detail.page.scss'],
})
export class ReceiptDetailPage implements OnInit {
  public receipt: ReceiptModel;
  public folderName: string;
  private subscription: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.subscription = this.route.params.subscribe(params => {
      this.folderName = params.folderName;
      this.dataService.getReceiptById(params.folderName, params.receiptId)
        .then(res => this.receipt = res);
    });
  }

}
